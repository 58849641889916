<template>
  <div class="Personal_contanir">
    <div class="tac">
      <el-menu default-active class="el-menu-vertical-demo" @select="handleSelect">
        <el-menu-item index="0" class="aaa">
          <el-tooltip class="item" effect="light" content="更改头像" placement="bottom">
            <img
              class="img"
              :src="this.relativeUserImg"
              alt
              @click="dialogVisible = !dialogVisible"
            />
            <!-- :onerror="defaultImg" -->
          </el-tooltip>
          <!-- <i class="el-icon-user-solid"></i> -->
          <span v-if="this.$store.state.userinfo.celebrity" class="VVVV"></span>
          <!-- 更改用户头像 -->
          <template>
            <el-dialog title="更换头像" :visible.sync="dialogVisible" width="30%">
              <el-upload
                :class="{ hide: uploadHide }"
                class="papaya"
                :action="uploadUrl()"
                accept="image/*"
                list-type="picture-card"
                :limit="1"
                :on-remove="handleRemove"
                :on-change="handleChange"
                :on-success="handleSuccess"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
              <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button
                  type="primary"
                  @click="
                      submit();
                      dialogVisible = false;
                    "
                >确 定</el-button>
              </span>
            </el-dialog>
          </template>
          <p>{{ this.$store.state.userinfo.name }}</p>
          <p class="clearfix" id>
            <span
              v-if="this.$store.state.userinfo.celebrity"
              style=" color: red;"
              class="position"
            >知识网红</span>
            <span>用户等级:{{ this.creditRating }}</span>
          </p>
          <p class="clearfix">
            <span class="position">可用知识币:</span>
            <!-- <span style="white-space:normal;" class="yellow">{{ (Money+zsjgPrepay).toFixed(2) }}</span> -->
            <span style="white-space:normal;" class="yellow">{{ (Money).toFixed(2) }}</span>
          </p>
          <p class="clearfix">
            <span class="position">已冻结:</span>
            <span style="white-space:normal;" class="yellow">{{ zsjgPrepay.toFixed(2) }}</span>
          </p>
          <p class="clearfix">
            <span class="position" v-html="'积 &nbsp;&nbsp;分:'"></span>
            <span style="white-space:normal;" class="yellow">{{ integral }}</span>
          </p>
        </el-menu-item>
        <el-menu-item index="1">
          <i class="el-icon-menu"></i>
          <span slot="title">
            <router-link to="Comment">与我相关</router-link>
          </span>
        </el-menu-item>
        <el-menu-item index="2">
          <i class="el-icon-document"></i>
          <span slot="title">
            <router-link to="PCCollection">我的账单</router-link>
          </span>
        </el-menu-item>

        <el-submenu index="3">
          <template slot="title">
            <i class="el-icon-setting"></i>账户设置
          </template>
          <el-menu-item index="3-1">
            <router-link to="Basicinformation">基本资料</router-link>
          </el-menu-item>
          <el-menu-item index="3-2">
            <router-link to="Passwordsettings">密码设置</router-link>
          </el-menu-item>
          <!-- 第三方登陆暂时隐藏 -->
          <el-menu-item v-if="false" index="3-3">
            <router-link to="Thirdparty">第三方登录</router-link>
          </el-menu-item>
        </el-submenu>
        <el-menu-item index="4">
          <!-- <i class="el-icon-setting"></i> -->
          <i class="el-icon-folder"></i>
          <span slot="title">
            <router-link to="PCresourxce">我的资源</router-link>
          </span>
        </el-menu-item>
        <el-menu-item index="5">
          <!-- <i class="el-icon-setting"></i> -->
          <i class="el-icon-help"></i>
          <span slot="title">
            <router-link to="PCmyTeam">我的团队</router-link>
          </span>
        </el-menu-item>
        <el-menu-item index="5">
          <!-- <i class="el-icon-setting"></i> -->
          <i class="el-icon-collection"></i>
          <span slot="title">
            <router-link to="PCmySubset">订阅设置</router-link>
          </span>
        </el-menu-item>
        <!-- <el-menu-item index="6">
          <i class="el-icon-collection"></i>
          <span slot="title">
            <router-link to="PCmySearch">建库查询</router-link>
          </span>
        </el-menu-item>
        <el-menu-item index="7">
          <i class="el-icon-medal-1"></i>
          <span slot="title">
            <router-link to="Revenue_inquiry">收益查询</router-link>
          </span>
        </el-menu-item>-->
        <el-menu-item index="6">
          <!-- <i class="el-icon-setting"></i> -->
          <i class="el-icon-document-add"></i>
          <span slot="title">
            <router-link to="PCmyContract">我的合约</router-link>
          </span>
        </el-menu-item>
        <el-menu-item index="7">
          <!-- <i class="el-icon-setting"></i> -->
          <i class="el-icon-data-line"></i>
          <span slot="title">
            <router-link to="Schedule">加工进度</router-link>
          </span>
        </el-menu-item>
        <!-- <el-menu-item index="7">
           
            <i class="el-icon-collection"></i>
            <span slot="title">
              <router-link to="PCmySubset">收益查询</router-link>
            </span>
          </el-menu-item>
          <el-menu-item index="8">
            
            <i class="el-icon-collection"></i>
            <span slot="title">
              <router-link to="PCmySubset">体现充值</router-link>
            </span>
        </el-menu-item>-->
      </el-menu>
    </div>
    <div id="My-containr" class="My-containr">
      <router-view />
    </div>
  </div>
</template>

<script>
import { DELETE, QUERY, INSERT, UPDATE, QUERYED } from "@/services/dao.js";
// import eventBus from '@/js/common/eventBus';
import { keepTwoDecimalFull } from "@/js/common/index";
export default {
  name: "Personal",
  components: {},
  data() {
    return {
      info: false,
      Money: 0.0, //知识币
      zsjgPrepay: 0, //被冻结知识币
      integral: 0, //积分
      dialogVisible: false, // 更改头像弹窗
      imageUrl: "", // 上传的头像地址
      dialogImageUrl: "",
      dialogVisible1: true, // 删除所选头像图片浮层
      uploadHide: false, // 隐藏上传按钮属性
      limitCount: 1, // + 的比对属性，与limit同步
      relativeUserImg: "", // 上传头像回显地址
      creditRating: 0 //用户信用等级
      // defaultImg:'this.src="' +this.api.LoginURL.concat("/resources/trading/upload/png/失败_1605163477794.png") +'"'
      // defaultImg:require('@/assets/默认头像0.png')
    };
  },
  watch:{
    integral(val){
      console.log(val,213);
    }
  },
  created() {
    this.init();
  },
  mounted() {
    //监听其他页面的调用,并触发视图更新
    this.$root.Hub.$on("oneEvent", (scored, Money) => {
      this.integral = scored;
      
      this.Money = Money;
    });
    this.$root.Hub.$on("oneEvent3", (img, integral, Money) => {
      
      this.relativeUserImg = this.api.LoginURL.concat(img);
      this.integral = integral;
      this.Money = Money;
    });
  },
  methods: {
    handleSelect(key, keyPath) {
      // console.log(key, keyPat0 h);
    },
    //图片加载失败
    imgLoadErr() {
      this.relativeUserImg = require("@/assets/默认头像0.png");
    },

    //获取用户数据
    async init() {
      let totals = await QUERYED(
        "post",
        "",
        "User(where:{id:{_eq:" +
          this.$store.state.userinfo.id +
          "}}) {Money creditRating  zsjg_prepay integral img}"
      );
        console.log(totals,456);
      let {
        Money,
        zsjg_prepay,
        integral,
        img,
        creditRating
      } = totals.data.User[0];
      this.Money = Money; //余额
      this.zsjgPrepay = zsjg_prepay; //被冻结知识币
      this.integral = integral;
      this.creditRating = creditRating;
      this.relativeUserImg = this.api.LoginURL.concat(img);
    },
    handleChange(file, fileList) {
      this.uploadHide = fileList.length >= this.limitCount;
    },
    // 删除已上传成功的图片
    handleRemove(file, fileList) {
      this.uploadHide = fileList.length >= this.limitCount;
      this.imageUrl = ""; // 清空图像图片暂存地址
    },
    // 图片上传成功的钩子
    handleSuccess(res, file, fileList) {
      if (res.success) {
        // 暂存图片上传地址 以备插入数据库
        this.imageUrl = res.data.url;
      }
    },

    // 确定则更新 user表 头像地址
    async submit() {
      let userID = this.$store.state.userinfo.id;
      if (this.imageUrl) {
        let info = await UPDATE(
          "POST",
          "",
          "  update_User(where: {id: {_eq: " +
            userID +
            '}}, _set: {img: "' +
            this.imageUrl +
            '"}) {  affected_rows }'
        );
        if (info.data.update_User.affected_rows == 1) {
          this.relativeUserImg = this.api.LoginURL.concat(this.imageUrl);
          this.$message.success("修改成功!");
        } else {
          this.$message.warning("服务器开了小差 待会儿再试试吧*——*");
        }
      } else {
        this.$message.warning("请选择头像!");
      }
    },
    uploadUrl: function() {
      return this.api.LoginURL.concat("/resources/trading/resourcesUpload");
    }
  }
};
</script>

<style scoped>
.Personal_contanir {
  background-color: #f7f7f7;
  border-top: 2px solid rgba(206, 213, 215, 1);
  width: 1000px;
  margin: auto;
}
#Information {
  width: 100%;

  background-size: 100% 100%;
}
#My-containr {
  overflow: hidden;
  background-color: #ffffff;
  padding: 20px;
  width: 760px;
  display: inline-block;
  vertical-align: top;
}
.el-menu-item {
  border-bottom: solid 1px #e8e8e8;
}
.el-menu-item a {
  position: relative;
  height: 100%;
  width: calc(100% - 60px);
  left: 0;
}
.router-link-active {
  color: rgb(255, 208, 75);
}
.el-menu-item.is-active {
  color: #303133;
}
.aaa {
  width: 100%;
  height: 180px;
}
.img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-top: 10px;
}
.aaa p {
  margin: 0;
  line-height: 20px;
  text-align: center;
}
.yellow {
  color: #fc8404;
}
/* 用户头像 */
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
/*重构样式*/
.papaya >>> .el-upload--picture-card {
  background-color: #fbfdff;
  border: 1px dashed #c0ccda;
  border-radius: 6px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  vertical-align: top;
  width: 133px;
  height: 133px;
  margin: 0;
  line-height: 133px;
}
.hide >>> .el-upload--picture-card {
  display: none;
}
/*个人中心图标*/

.myteam {
  background: url(~@/assets/icon/myTeam.png) no-repeat;
}
.mysource {
  background: url(~@/assets/icon/mySource.png) no-repeat;
}
.public-icon {
  width: 20px;
  height: 20px;
  background-size: 20px;
  display: inline-block;
  margin-right: 8px;
  margin-left: 3px;
}
.item {
  margin: 4px;
}
#margin-t-15px {
  margin-top: 15px;
}
.clearfix::after {
  clear: both;
  display: block;
  content: "";
}
.position {
  float: left;
  /* left: 60px; */
  padding-left: 30px;
  width: 75px;
  text-align: right;
  /* background-color: darkorange; */
}
/* elmentUI样式重构 */
.Personal_contanir >>> .el-menu-item {
  cursor: default;
}
[v-cloak] {
  display: none !important;
}
.tac {
  width: 200px;
  display: inline-block;
}
.tac >>> .el-col.el-col-4 {
  width: 22.66667%;
}
.tac >>> .el-menu-item.aaa {
  padding: 0 0;
  text-align: center;
  padding-left: 0 !important;
}
.tac >>> .el-menu-vertical-demo.el-menu {
  border: 1px solid #e6e6e6;
}
.tac >>> .el-menu-vertical-demo.el-menu li:not(:first-child) {
  text-align: center;
}
.tac >>> .el-menu-vertical-demo.el-menu li:nth-child(4) {
  border-bottom: 1px solid #e6e6e6;
}
.PN >>> .el-menu-demo.el-menu--horizontal.el-menu li {
  width: 25%;
  margin-left: 0;
  text-align: center;
}
.PN >>> .el-menu-demo.el-menu--horizontal.el-menu .el-menu-item a {
  position: relative;
  height: 100%;
  width: calc(100% - 60px);
  left: 0;
}
.el-icon-medal-1 {
  font-size: 14px;
}
.VVVV {
  color: #fda444;
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  top: 50px;
  left: 110px;
  display: inline-block;
  width: 15px;
  height: 15px;
  /* opacity: 0.9; */
  background-size: 100% 100%;
  background-image: url("~@/assets/icon/v.png");
}
</style>
